import React, { FC } from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import HeroBanner from 'gatsby-theme-husky/src/components/HeroBanner';
import IconRow from 'gatsby-theme-husky/src/components/IconRow';
import TwoColumnPromoProduct from 'gatsby-theme-husky/src/components/TwoColumnPromoProduct';
import { IPromoProductDurexViewComponentProps } from 'gatsby-theme-husky/src/components/views/PromoProductDurexView/models';
import Video from 'gatsby-theme-husky/src/components/Video';

import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import getResponsiveImageData from 'gatsby-theme-husky/src/utils/images';

import 'gatsby-theme-husky/src/components/views/PromoProductDurexView/PromoProductDurexView.scss';
import HPCarousel from 'gatsby-theme-husky/src/components/HPCarousel';
import HeaderImagesRow from '../../HeaderImagesRow';

const PromoProductDurexView: FC<IPromoProductDurexViewComponentProps> = ({
  promoProductPageTitle,
  promoProductPageSubtitle,
  productsImagesSection,
  productsImagesBackground,
  productsImagesBackgroundMobile,
  paralaxMainImage,
  twoColumnPromoRows,
  fullWidthHeroBannerImage,
  bottomTitle,
  video,
  bottomMainHeroBanner,
  bottomMainHeroBanner: [
    {
      properties: { image, imageAlt, title, description, button, imageMobile },
    },
  ],
  whatWorksHeading,
  whatWorksIconSection,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();

  const imageData = getResponsiveImageData(paralaxMainImage?.[0].properties, {
    isMobile,
    isTablet,
    isDesktop,
  });

  const specificImage = isMobile && imageMobile ? imageMobile.gatsbyImage : image.gatsbyImage;

  return (
    <div className="promo-product-durex-view">
      <HeaderImagesRow
        title={promoProductPageTitle}
        images={productsImagesSection}
        productsImagesBackground={productsImagesBackground}
        productsImagesBackgroundMobile={productsImagesBackgroundMobile}
        description={promoProductPageSubtitle}
      />
      <div className="what-works">
        <h5 className="what-works__title">{whatWorksHeading}</h5>
        <IconRow images={whatWorksIconSection} />
      </div>
      {isDesktop && paralaxMainImage ? (
        <BackgroundImage
          Tag="section"
          className="parallax-image-dm"
          fluid={imageData?.childImageSharp.fluid}
        />
      ) : (
        <div className="parallax-image-dm">
          <GatsbyImage
            key={paralaxMainImage?.[0].properties.imageAltLabel}
            className="promo-product__parallax-img"
            fluid={imageData?.childImageSharp.fluid}
            alt={paralaxMainImage?.[0].properties?.imageAltLabel}
            objectPosition="50% 0%"
          />
        </div>
      )}
      <TwoColumnPromoProduct {...{ twoColumnPromoRows }} />
      {fullWidthHeroBannerImage?.[0] ? (
        <section className="promo-product__fullwidthhero">
          <GatsbyImage
            key={fullWidthHeroBannerImage?.[0].properties.imageAltLabel}
            className="promo-product__fullwidthheroimg"
            fluid={fullWidthHeroBannerImage?.[0].properties.image?.childImageSharp.fluid}
            alt={fullWidthHeroBannerImage?.[0].properties.imageAltLabel}
          />
        </section>
      ) : null}
      {bottomTitle ? (
        <section className="promo-product__madefor">
          <h2 className="promo-product__madefortitle">{bottomTitle}</h2>
          {video?.map(({ properties: videoProperties }, videoIndex) => (
            <Video
              key={`video-${videoProperties.imageAlt || videoIndex}`}
              className="survey-page__video"
              thumbnail={videoProperties.localImage}
              videoURL={videoProperties.videoLink || videoProperties.videoCMS?.fallbackUrl}
            />
          ))}
        </section>
      ) : null}
      {bottomMainHeroBanner.length === 1 ? (
        <HeroBanner
          inFirstViewport
          image={specificImage}
          altImageText={imageAlt}
          title={title}
          hasVerticalPadding={false}
          button={button}
          className="article-listing-hero-banner"
          description={description}
          headerLevel={2}
        />
      ) : (
        <HPCarousel inFirstViewport items={bottomMainHeroBanner || []} />
      )}
    </div>
  );
};

export default PromoProductDurexView;

export const query = graphql`
  fragment FragmentIconRow on ImageWithAltAndDecsp {
    properties {
      description
      imageAltLabel
      image {
        childImageSharp {
          fluid(maxWidth: 300, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
