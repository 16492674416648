import React, { FC } from 'react';
import classnames from 'classnames';

import { IHeaderImagesRowComponentProps } from 'gatsby-theme-husky/src/components/HeaderImagesRow/models';
import 'gatsby-theme-husky/src/components/HeaderImagesRow/HeaderImagesRow.scss';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

const HeaderImagesRow: FC<IHeaderImagesRowComponentProps> = ({
  title,
  description,
  images,
  productsImagesBackground,
  productsImagesBackgroundMobile,
}) => {
  const { isMobile } = useScreenRecognition();

  return productsImagesBackgroundMobile &&
    productsImagesBackground &&
    productsImagesBackground[0] ? (
    <div className="headerimagesrow-special">
      {isMobile ? (
        <div>
          <GatsbyImage
            key={productsImagesBackgroundMobile[0].properties.imageAltLabel}
            className="header-images-row__background"
            fluid={productsImagesBackgroundMobile[0].properties.image?.childImageSharp.fluid}
            alt={productsImagesBackgroundMobile[0].properties.imageAltLabel}
          />
        </div>
      ) : (
        <div>
          <GatsbyImage
            key={productsImagesBackground[0].properties.imageAltLabel}
            className="header-images-row__background"
            fluid={productsImagesBackground[0].properties.image?.childImageSharp.fluid}
            alt={productsImagesBackground[0].properties.imageAltLabel}
          />
        </div>
      )}
      <div className="headerimagesrow-special__content">
        {images ? (
          <div className="headerimagesrow-special__imgwrapper">
            <p className="headerimagesrow__description">{description}</p>
            {images.map(({ properties: { imageAltLabel, image } }) => (
              <GatsbyImage
                key={imageAltLabel}
                className="headerimagesrow-special__image"
                fluid={image?.childImageSharp.fluid}
                alt={imageAltLabel}
              />
            ))}
          </div>
        ) : null}
        <DangerouslySetInnerHtml
          html={title}
          className="headerimagesrow-special__title"
          element="h1"
        />
      </div>
    </div>
  ) : (
    // fallback to default layout
    <div className="headerimagesrow" data-testid="headerimagesrow">
      {productsImagesBackground &&
        productsImagesBackground[0] &&
        productsImagesBackground[0].properties && (
          <div className="headerimagesrow__bg-imgwrapper">
            <GatsbyImage
              key={productsImagesBackground[0].properties.imageAltLabel}
              className="header-images-row__background"
              fluid={productsImagesBackground[0].properties.image?.childImageSharp.fluid}
              alt={productsImagesBackground[0].properties.imageAltLabel}
            />
          </div>
        )}

      <DangerouslySetInnerHtml html={title} className="headerimagesrow__title" element="h1" />
      {images ? (
        <div
          className={classnames('headerimagesrow__imgwrapper', {
            'headerimagesrow__imgwrapper--single': images.length === 1,
          })}
        >
          {images.map(({ properties: { imageAltLabel, image } }) => (
            <GatsbyImage
              key={imageAltLabel}
              className="header-images-row__image"
              fluid={image?.childImageSharp.fluid}
              alt={imageAltLabel}
            />
          ))}
        </div>
      ) : null}
      <p className="headerimagesrow__description">{description}</p>
    </div>
  );
};
// Use special layout if productsImagesBackgroundMobile is available

export default HeaderImagesRow;
