import React, { FC } from 'react';

import { IDiscoverViewComponentProps } from 'components/views/DiscoverView/models';
import DmFullWidthPromoProduct from 'gatsby-theme-husky/src/components/DmFullWidthPromoProduct/DmFullWidthPromoProduct';
import DmDeclimer from 'components/DmDeclimer';
import './DiscoverView.scss';

import DmTwoColumnParallax from 'components/DmTwoColumnParallax';
import Video from 'components/Video';
import HeroBanner from 'components/HeroBanner';
import HPCarousel from 'components/HPCarousel';
import useScreenRecognition from 'hooks/useScreenRecognition';

const DiscoverView: FC<IDiscoverViewComponentProps> = ({
  dMPromoProductPageTitle,
  dMValuesSection,
  dMParalaxSection,
  dMDisclaimerTitle,
  dMDisclaimerDescription,
  dMBottomTitle,
  dMVideo,
  dMBottomMainHeroBanner,
  dMBottomMainHeroBanner: [
    {
      properties: { image, imageAltHero, title, button, imageMobile, description },
    },
  ],
}) => {
  const { isMobile } = useScreenRecognition();
  const specificImage = isMobile && imageMobile ? imageMobile.gatsbyImage : image.gatsbyImage;

  return (
    <div className="discover-view">
      <DmFullWidthPromoProduct {...{ dMPromoProductPageTitle, dMValuesSection }} />
      {dMParalaxSection
        ? dMParalaxSection?.map((parallaxSection, i) => (
            <DmTwoColumnParallax
              {...{ parallaxSection }}
              reverse={i % 2 === 0}
              key={parallaxSection.properties.paralaxTitle}
            />
          ))
        : null}
      <DmDeclimer {...{ dMDisclaimerTitle, dMDisclaimerDescription }} />
      <section className="promo-product__madefor">
        <h2 className="promo-product__madefortitle">{dMBottomTitle}</h2>
        {dMVideo?.map(
          ({ properties: { imageAlt, localImage, videoLink, videoCMS } }, videoIndex) => (
            <Video
              key={`video-${imageAlt || videoIndex}`}
              className="survey-page__video"
              thumbnail={localImage}
              videoURL={videoLink || videoCMS?.fallbackUrl}
            />
          )
        )}
      </section>
      {dMBottomMainHeroBanner.length === 1 ? (
        <HeroBanner
          inFirstViewport
          image={specificImage}
          altImageText={imageAltHero}
          title={title}
          hasVerticalPadding={false}
          description={description}
          button={button}
          className="article-listing-hero-banner"
        />
      ) : (
        <HPCarousel inFirstViewport items={dMBottomMainHeroBanner || []} />
      )}
    </div>
  );
};

export default DiscoverView;
