import React, { FC } from 'react';

import { IDmFullWidthPromoProductComponentProps } from 'components/DmFullWidthPromoProduct/models';
import { Container, Row, Col } from 'layout';
import 'components/DmFullWidthPromoProduct/DmFullWidthPromoProduct.scss';
import GatsbyImage from 'common/GatsbyImage';

const DmFullWidthPromoProduct: FC<IDmFullWidthPromoProductComponentProps> = ({
  dMPromoProductPageTitle,
  dMValuesSection,
}) => (
  <Container fluid>
    <div className="dm-full-width-promo-product" data-testid="dm-product">
      <h2 className="dm-full-width-promo-product__title">{dMPromoProductPageTitle}</h2>
      <div className="dm-full-width-promo-product__icons">
        <Row>
          {dMValuesSection?.map(({ properties: { image, imageAltLabel, description } }) => (
            <Col sm="12" lg="4" key={imageAltLabel}>
              <div className="icon">
                {image?.childImageSharp?.fluid ? (
                  <GatsbyImage
                    key={imageAltLabel}
                    className="getcloser__image"
                    fluid={image?.childImageSharp.fluid}
                    alt={imageAltLabel}
                    objectFit="contain"
                  />
                ) : null}
                <p className="description">{description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  </Container>
);

export default DmFullWidthPromoProduct;
