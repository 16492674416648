import React, { FC } from 'react';
import { Container, Row, Col } from 'layout';
import { IDmDeclimerComponentProps } from 'components/DmDeclimer/models';
import 'components/DmDeclimer/DmDeclimer.scss';

const DmDeclimer: FC<IDmDeclimerComponentProps> = ({
  dMDisclaimerTitle,
  dMDisclaimerDescription,
}) => (
  <Container>
    <Row>
      <Col>
        <div className="dm-disclimer" data-testid="dm-disclimer">
          <h3 className="dm-disclimer__title">{dMDisclaimerTitle}</h3>
          <p className="dm-disclimer__description">{dMDisclaimerDescription}</p>
        </div>
      </Col>
    </Row>
  </Container>
);

export default DmDeclimer;
