import React, { FC } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Container, Row, Col } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { IDmTwoColumnParallaxComponentProps } from 'components/DmTwoColumnParallax/models';
import 'components/DmTwoColumnParallax/DmTwoColumnParallax.scss';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

const DmTwoColumnParallax: FC<IDmTwoColumnParallaxComponentProps> = ({
  parallaxSection: {
    properties: { paralaxTitle, paralaxDescription, paralaxImage },
  },
  reverse,
}) => {
  const { isLargeDesktop } = useScreenRecognition();

  return (
    <Container fluid className="dm-parallax" data-testid="dm-parallax">
      <Row>
        <Col sm="12" xl="6" className="image">
          {paralaxImage ? (
            <div className="dm-parallax__image image-radius">
              <div className="dm-parallax__parallax">
                <section
                  className={classNames(`dm-parallax__overflow`, {
                    'dm-parallax__overflow--reverse': reverse,
                  })}
                >
                  <div className="dm-parallax__scroller">
                    {paralaxImage && isLargeDesktop ? (
                      <BackgroundImage
                        Tag="section"
                        className={classNames(`parallax-image-dm parallax-image-dm--overright`, {
                          'parallax-image-dm--overleft': reverse,
                        })}
                        fluid={paralaxImage.childImageSharp.fluid}
                        alt={paralaxTitle}
                      />
                    ) : (
                      <GatsbyImage
                        key={paralaxTitle}
                        className="dm-parallax__img"
                        fluid={paralaxImage.childImageSharp.fluid}
                        alt={paralaxTitle}
                      />
                    )}
                  </div>
                </section>
              </div>
            </div>
          ) : null}
        </Col>
        <Col sm="12" xl="6">
          <div className="dm-parallax__text">
            <h2 className="title">{paralaxTitle}</h2>
            <p className="description">{paralaxDescription}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DmTwoColumnParallax;
