import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';

import { DangerouslySetInnerHtml } from 'layout';
import PromoProductIcon from 'components/PromoProductIcon';

import { IPromoRowsProductItem } from '../model';

const TwoColumnPromoProductRow: FC<IPromoRowsProductItem> = ({
  properties: {
    title,
    description: descriptionTitle,
    optionalDescription,
    imagePackshot: [
      {
        properties: { image, imageAltLabel },
      },
    ],
    bannerBackgroundImage,
    bannerGradientBgColorFrom,
    bannerGradientBgColorTo,
    button,
    iconsSection,
  },
  idToScroll,
}) => {
  const backgroundGradient = bannerBackgroundImage
    ? 'unset'
    : `linear-gradient(180deg, #${bannerGradientBgColorFrom.value}, #${bannerGradientBgColorTo.value} )`;

  return (
    <div className="two-column-promo__row" id={idToScroll}>
      <div
        className="row-image"
        style={{
          background: backgroundGradient,
        }}
      >
        <div className="row-image__container">
          {image ? (
            <GatsbyImage
              wrapperClasses="row-image__product"
              alt={imageAltLabel}
              fluid={image?.childImageSharp?.fluid}
            />
          ) : null}
          {button[0] ? (
            <Button
              btnColorVariant="transparent-white"
              link={button[0].properties.buttonLink[0].url}
              ariaLabel={button[0].properties.ariaLabel}
            >
              {button[0].properties.buttonText}
            </Button>
          ) : null}
        </div>
        <div className="row-image__product-background">
          {bannerBackgroundImage ? (
            <GatsbyImage
              alt={bannerBackgroundImage?.[0]?.properties?.imageAlt}
              fluid={bannerBackgroundImage?.[0]?.properties?.image?.childImageSharp?.fluid}
            />
          ) : null}
        </div>
      </div>
      <div className="row-info">
        <div className="text-section">
          <div className="text-section__container">
            {title ? <h3 className="row-info__title">{title}</h3> : null}
            {descriptionTitle ? (
              <DangerouslySetInnerHtml className="row-info__description" html={descriptionTitle} />
            ) : null}
            {optionalDescription ? (
              <DangerouslySetInnerHtml
                className="row-info__optional-description"
                html={optionalDescription}
              />
            ) : null}
          </div>
        </div>
        <div className="icopromo-section">
          {iconsSection?.map(
            ({
              properties: {
                bgColor,
                description,
                iconAltLabel,
                optionalDescription: optDescription,
                icon,
              },
            }) => (
              <div
                className="icopromo-container"
                key={description}
                style={{ background: `#${bgColor.value}` }}
              >
                <PromoProductIcon {...{ description, iconAltLabel, optDescription, icon }} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default TwoColumnPromoProductRow;
