import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import { Link } from 'react-scroll';

import { IIconRowComponentProps } from './models';
import './IconRow.scss';

const IconRow: FC<IIconRowComponentProps> = ({ images }) => {
  const settings = {
    duration: 500,
    spy: true,
    smooth: true,
  };

  return (
    <div className="iconrow">
      {images?.map(({ properties: { description, image, imageAltLabel } }, index) => (
        <Link {...settings} to={`twoColumn${index}`}>
          <div className="iconrow__tiles">
            {image ? (
              <div className="iconrow__image">
                <GatsbyImage fluid={image?.childImageSharp.fluid} alt={imageAltLabel} />
              </div>
            ) : null}
            <h5 className="iconrow__description">{description}</h5>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default IconRow;
