import { graphql } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Container } from 'gatsby-theme-husky/src/layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PromoProductNavigation from 'gatsby-theme-husky/src/components/PromoProductNavigation';
import PromoProductDurexView from 'gatsby-theme-husky/src/components/views/PromoProductDurexView';
import DiscoverView from 'gatsby-theme-husky/src/components/views/DiscoverView';
import TopFooter from 'gatsby-theme-husky/src/components/Footer/TopFooter/TopFooter';
import BottomFooter from 'gatsby-theme-husky/src/components/Footer/BottomFooter/BottomFooter';

import { PromoProductPageComponentProps } from 'gatsby-theme-husky/src/templates/PromoProductPage/models';
import 'gatsby-theme-husky/src/templates/PromoProductPage/PromoProductPage.scss';

const PromoProductPage: FC<PromoProductPageComponentProps> = ({
  data: {
    umbracoPromoProduct: {
      pageTheme,
      seoMetaDescription,
      seoMetaKeywords,
      seoMetaTitle,
      seoExternalHreflangs,
      discoverMoreBtnText,
      productNameBtnText,
      navigation,
      defaultCompositions,
      twoColumnPromoRows,
      promoProductPageTitle,
      promoProductPageSubtitle,
      whatWorksHeading,
      bottomMainHeroBanner,
      paralaxMainImage,
      video,
      fullWidthHeroBannerImage,
      bottomTitle,
      whatWorksIconSection,
      productsImagesSection,
      productsImagesBackground,
      productsImagesBackgroundMobile,
      dMPromoProductPageTitle,
      dMValuesSection,
      dMParalaxSection,
      dMDisclaimerTitle,
      dMDisclaimerDescription,
      dMBottomTitle,
      dMVideo,
      dMBottomMainHeroBanner,
    },
    brandSettings,
  },
}) => {
  const [activeSlide, setActiveSlide] = useState<boolean>(true);

  const { copyrightBlock, hideMediaLinks } = defaultCompositions.footer;

  useEffect(() => {
    twoColumnPromoRows.forEach((element) => {
      if (element.properties.bannerBackgroundImage) {
        element.properties.bannerBackgroundImage[0].properties.imageAlt =
          element.properties.bannerBackgroundImage[0].properties.imageAltLabel;
      }
    });
  }, []);

  return (
    <>
      <Seo
        siteSettings={defaultCompositions.siteSettings}
        brandSettings={brandSettings}
        {...{
          seoMetaDescription,
          seoMetaKeywords,
          seoMetaTitle,
          seoExternalHreflangs,
        }}
        lang={defaultCompositions.siteSettings.lang}
      />
      <div
        className={classnames('promo-product-page', {
          [`promo-product-page-${pageTheme}`]: pageTheme,
        })}
      >
        <Container fluid className="promo-product-page__navigation">
          <Container>
            <PromoProductNavigation
              {...{
                discoverMoreBtnText,
                productNameBtnText,
                navigation,
                activeSlide,
                setActiveSlide,
              }}
            />
          </Container>
        </Container>

        {activeSlide ? (
          <PromoProductDurexView
            {...{
              twoColumnPromoRows,
              promoProductPageTitle,
              productsImagesSection,
              productsImagesBackground,
              productsImagesBackgroundMobile,
              promoProductPageSubtitle,
              paralaxMainImage,
              bottomMainHeroBanner,
              fullWidthHeroBannerImage,
              bottomTitle,
              whatWorksHeading,
              whatWorksIconSection,
              video,
            }}
          />
        ) : (
          <DiscoverView
            {...{
              dMPromoProductPageTitle,
              dMValuesSection,
              dMParalaxSection,
              dMDisclaimerTitle,
              dMDisclaimerDescription,
              dMBottomTitle,
              dMVideo,
              dMBottomMainHeroBanner,
            }}
          />
        )}
        <footer className="footer">
          <Container className="footer-container">
            <TopFooter
              dataLogo={brandSettings}
              dataMediaLinks={brandSettings.mediaLinks}
              dataFooterHideMediaLinks={hideMediaLinks}
            />
            <BottomFooter dataCopyright={copyrightBlock} />
          </Container>
        </footer>
      </div>
    </>
  );
};

export const query = graphql`
  query PromoProductPageAU($url: String = "") {
    umbracoPromoProduct(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      pageTheme
      promoProductPageTitle
      promoProductPageSubtitle
      productsImagesSection {
        properties {
          imageAltLabel
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90, sizes: "(max-width: 1260px) 100vw, 50vw") {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      productsImagesBackground {
        ...FragmentImageWithAlt
      }
      productsImagesBackgroundMobile {
        ...FragmentImageWithAlt
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      productNameBtnText
      discoverMoreBtnText
      whatWorksHeading
      whatWorksIconSection {
        ...FragmentIconRow
      }
      navigation {
        properties {
          image {
            childImageSharp {
              fluid(maxWidth: 195, quality: 100, sizes: "(max-width: 1199px) 108px, 195px") {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          altLabel
          link {
            url
          }
          buttonText
          buttonLink {
            url
          }
        }
      }
      twoColumnPromoRows {
        ...TwoColumnPromoRowsProductTypes
      }
      bottomMainHeroBanner {
        properties {
          title
          description
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageMobile {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageSm: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageMd: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageAlt
          button {
            properties {
              ariaLabel
              buttonText
              buttonLink {
                url
              }
            }
          }
          description
        }
      }
      fullWidthHeroBannerImage {
        ...FragmentImageWithAlt
      }
      bottomTitle
      video {
        ...FragmentVideo
      }
      paralaxMainImage {
        properties {
          imageAltLabel
          mobileImage: image {
            childImageSharp {
              fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tabletImage: image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: image {
            childImageSharp {
              fluid(maxWidth: 5400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      dMPromoProductPageTitle
      dMValuesSection {
        properties {
          description
          imageAltLabel
          optionalDescription
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      dMParalaxSection {
        properties {
          paralaxDescription
          paralaxTitle
          paralaxImage {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      dMDisclaimerTitle
      dMDisclaimerDescription
      dMBottomTitle
      dMVideo {
        ...FragmentVideo
      }
      dMBottomMainHeroBanner {
        properties {
          title
          description
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageMobile {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageSm: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageMd: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageAlt
          button {
            properties {
              ariaLabel
              buttonText
              buttonLink {
                url
              }
            }
          }
          description
        }
      }
    }
    brandSettings {
      brandName
      brandLogo {
        fallbackUrl
        svg {
          content
        }
      }
      openGraphImage {
        url
      }
      brandLogoAlt
      brandLogoLinkAriaLabel
      brandName
      youtubeUrl
      facebookUrl
      mediaLinks {
        properties {
          mediaAriaLabel
          altLabel
          image {
            fallbackUrl
            svg {
              content
            }
          }
          link {
            url
            name
          }
        }
      }
    }
  }
`;

export default PromoProductPage;
