import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import { IPromoProductIconComponentProps } from 'components/PromoProductIcon/models';
import 'components/PromoProductIcon/PromoProductIcon.scss';

const PromoProductIcon: FC<IPromoProductIconComponentProps> = ({
  description,
  iconAltLabel,
  optDescription,
  icon,
}) => (
  <div className="icopromo" data-testid="icon-container">
    {icon?.childImageSharp?.fluid ? (
      <GatsbyImage fluid={icon?.childImageSharp?.fluid} alt={iconAltLabel} />
    ) : null}
    {description ? <p className="icopromo__title">{description}</p> : null}
    {optDescription ? <p className="icopromo__optional-description">{optDescription}</p> : null}
  </div>
);

export default PromoProductIcon;
