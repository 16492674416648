import React, { FC } from 'react';
import { graphql } from 'gatsby';
import TwoColumnPromoProductRow from './TwoColumnPromoProductRow';
import { ITwoColumnPromoProductProps } from './model';
import './TwoColumnPromoProduct.scss';
import './TwoColumnPromoProductExtends.scss';
import './TwoColumnPromoProductRTL.scss';

const TwoColumnPromoProduct: FC<ITwoColumnPromoProductProps> = ({ twoColumnPromoRows = [] }) =>
  twoColumnPromoRows?.length ? (
    <div className="two-column-promo-product" data-testid="two-column-promo-product">
      {twoColumnPromoRows.map(({ properties }, index) => {
        const idKey = `${properties?.title}_${index}`;

        return (
          <TwoColumnPromoProductRow
            key={idKey}
            idToScroll={`twoColumn${index}`}
            properties={properties}
          />
        );
      })}
    </div>
  ) : null;
export default TwoColumnPromoProduct;

export const query = graphql`
  fragment TwoColumnPromoRowsProductTypes on TwoColumnPromoRowsTypes {
    properties {
      title
      optionalDescription
      bannerGradientBgColorFrom {
        label
        value
      }
      bannerGradientBgColorTo {
        label
        value
      }
      button {
        ...FragmentButton
      }
      cta {
        properties {
          ariaLabel
          buttonColor
          buttonText
          buttonLink {
            url
            icon
          }
        }
      }
      description
      iconsSection {
        properties {
          bgColor {
            label
            value
          }
          description
          iconAltLabel
          optionalDescription
          icon {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      imagePackshot {
        properties {
          imageAltLabel
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      bannerBackgroundImage {
        properties {
          imageAltLabel
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
