interface Breakpoints {
  isMobile: boolean | null;
  isTablet: boolean | null;
  isDesktop: boolean | null;
}

interface ImageSet {
  mobileImage: Internal.LocalImage;
  tabletImage: Internal.LocalImage;
  desktopImage: Internal.LocalImage;
}

export default function getResponsiveImageData(
  imageSet: ImageSet,
  breakpoints: Breakpoints
): Internal.LocalImage {
  if (breakpoints.isMobile) return imageSet?.mobileImage;
  if (breakpoints.isTablet) return imageSet?.tabletImage;

  return imageSet?.desktopImage;
}
